.button {
  border-radius: 6px;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 16px;
}

.primary {
  background-color: var(--body-mind-spirit);
  color: var(--purple);
}
.primary:hover {
  background-color: var(--body-mind-spirit-light);
}

.secondary {
  background-color: #6c757d;
}

.outlined {
  background-color: white;
  color: #007bff;
  border: 2px solid #007bff;
}
