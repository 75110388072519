.react-calendar {
  max-width: 100%;
  width: 100% !important;
  background: white;
  font-family: "Nunito", sans-serif;
  line-height: 1.125em;
  border-radius: 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  font-family: "Nunito", sans-serif;
  height: 44px;
  margin-bottom: 1em;
  display: flex;
  text-decoration: none;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
  border-radius: 4px;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
  border-radius: 4px;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  text-decoration: none;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;

  font-size: 0.75em;
}
.react-calendar__month-view__days__day--weekend {
  color: #4a0831;
  font-weight: bold;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  text-align: center;
  min-height: 50px;
  min-width: 50px;
  background: #f7f6f8;
  color: black;
  margin: 1px;
  font-weight: 700;
}

abbr {
  text-decoration: none;
}

.react-calendar__tile > abbr {
  position: relative;
  bottom: -4px;
}
.react-calendar__tile:disabled {
  background-color: #eeedf2;
  color: #a09fa2;
  border-radius: 0px;
}

.react-calendar__tile:enabled:focus {
  background-color: #f6543b;
  color: white;
  border-radius: 4px;
}

.react-calendar__tile:enabled:focus:hover {
  background-color: #f6543b;
  color: white;
  border-radius: 4px;
  opacity: 1 !important;
}

.react-calendar__tile:enabled:hover {
  background-color: #f6543b;
  opacity: 0.6;
}
.react-calendar__tile--now {
  border: 1px solid #f6543b !important;
  padding: 1rem;
  border-radius: 4px;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.react-calendar__tile--hasActive {
  /*background: red;*/
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #4a0831;
}
.react-calendar__tile--active {
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  opacity: 1 !important;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #fabf97;
}

.react-calendar__tile--range {
  background-color: #fabf97;
}

.react-calendar__tile--rangeEnd {
  background-color: #f6543b !important;
  color: white;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.react-calendar__tile--rangeStart {
  background-color: #f6543b !important;
  color: white;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.react-calendar__tile--active .react-calendar__tile--range {
  background-color: blue !important;
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none;
}

@media only screen and (max-width: 600px) {
  .react-calendar__tile {
    min-width: auto;
  }
}
