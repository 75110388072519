:root {
  --font-space-grotesk: "Space Grotesk", sans-serif;
  --font-input: "Space Grotesk", sans-serif;

  --snow: #fefeed;
  --community-light: #f3f3e0;
  --body-mind-spirit: #e8ceff;
  --body-mind-spirit-light: #f9edf5;
  --body-mind-spirit-heavy: #cbb2e1;
  --diet-light: #eee7cf;
  --black: #2e281d;
  --lighter-black: "";
  --lightest-black: rgba(46, 40, 29, 0.2);
  --purple: #7600be;
  --red: rgba(237, 49, 76, 1);
  --green: rgba(28, 177, 2, 1);
}

html,
body,
#__next {
  background-color: var(--snow);
  height: 100%;
}

h1 {
  font-family: var(--font-space-grotesk);
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  color: var(--black);
}

h2 {
  font-family: var(--font-space-grotesk);
  font-size: 1.3em;
  font-weight: 600;
  color: var(--purple);
}

h3 {
  font-family: var(--font-space-grotesk);
  font-size: 1.25em;
  font-weight: 600;
  color: var(--purple);
}

h4 {
  font-family: var(--font-space-grotesk);
  font-size: 1.2em;
  font-weight: 700;
  color: var(--purple);
  line-height: normal;
}

h5 {
  font-family: var(--font-space-grotesk);
  font-size: 1em;
  font-weight: 600;
  color: var(--black);
  line-height: normal;
}

p,
label,
a {
  font-family: var(--font-space-grotesk) !important;
  font-size: 1em;
  font-weight: 400;
  color: var(--black);
  line-height: normal;
}

.view-title {
  margin-bottom: 2rem;
  font-size: 2em;
  padding-right: 4rem;
  font-weight: 900;
  color: var(--purple);
}

.view-subtitle {
  font-weight: 800;
  font-family: var(--font-2);
  font-size: 1.4em;
  line-height: normal;
}

button {
  cursor: pointer;
}

.error {
  color: var(--red);
}

.success {
  color: var(--green);
}

textarea,
input[type="text"],
input[type="submit"],
input[type="time"],
input[type="email"],
input[type="password"] {
  padding: 0.5rem;
  border-radius: 12px;
  font-family: var(--font-input);
  border: none;
  outline: none;
  border: 1px solid #ccc;

  box-shadow: 0px 0px 2px 0px rgba(46, 40, 29, 0.12);
}

input[type="text"]:focus,
input[type="submit"]:focus,
input[type="time"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
  border-color: var(--body-mind-spirit);
}

form > input[type="text"],
form > input[type="submit"],
form > input[type="time"],
form > input[type="password"] {
  width: 100%;
  display: block;
  margin-bottom: 1rem;
  border-radius: 5px;
  padding: 0.5rem;
  border: 1px solid var(--gray);
  box-sizing: border-box; /* add this */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.time-picker {
  font-family: var(--font-2);
  font-weight: 700;
  padding: 0.5rem;
  border-radius: 4px;
  border: none;
  width: 100px;
  border: 1px solid var(--gray);
}

.flex-column-on-mobile {
  display: flex;
  flex-direction: row;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-underline:hover {
  text-decoration: underline;
}

.hover-orange:hover {
  color: var(--orange) !important;
}

.hidden-on-desktop {
  display: none;
}

.full-width-on-mobile {
  width: auto;
}

.flex-on-mobile {
  display: block;
}

.text-large {
  font-size: 40px;
}

@media only screen and (max-width: 600px) {
  .view-title {
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
  }

  .flex-on-mobile {
    display: flex;
  }

  .hidden-on-mobile {
    display: none;
  }

  .full-width-on-mobile {
    width: 100%;
  }

  .hidden-on-desktop {
    display: contents;
  }
  .flex-column-on-mobile {
    flex-direction: column;
  }

  .row-reverse-on-mobile {
    flex-direction: column-reverse;
  }
}

.text-center {
  text-align: center;
}

.decoration-none {
  text-decoration: none;
}

.text-small {
  font-size: 14px;
}

.display-none {
  display: none;
}

.app-padding {
  padding: 1rem;
}

.app-side-padding {
  padding-left: 1rem;
  padding-right: 1rem;
}

.center-child {
  display: flex;
  justify-content: center;
}

.divider {
  height: 1px;

  background-color: var(--gray);
}
.pointer {
  cursor: pointer;
}
.width-100 {
  width: 100%;
}

.flex {
  display: flex;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.underline {
  text-decoration: underline;
}

.flex {
  display: flex;
}

.border-none {
  border: none;
}

.italic {
  font-style: italic;
}

.block {
  display: block;
}

.center {
  text-align: center;
}

.font-300 {
  font-weight: 300;
}

.font-400 {
  font-weight: 400;
}
.font-500 {
  font-weight: 500;
}

.font-600 {
  font-weight: 600;
}
.font-700 {
  font-weight: 700;
}
.font-800 {
  font-weight: 800;
}

.height-100 {
  height: 100%;
}

.width-50 {
  width: 50%;
}
.absolute {
  position: absolute;
}

.float-right {
  float: right;
}
.float-left {
  float: left;
}
.inline-block {
  display: inline-block;
}
.block {
  display: block;
}

.column {
  flex-direction: column;
}

.flex-1 {
  flex: 1 !important;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}

.float-left {
  float: left;
}
.float-right {
  float: right;
}

.relative {
  position: relative;
}

.opacity-05 {
  opacity: 0.3;
}

.center-content {
  margin-left: auto;
  margin-right: auto;
}

.child-margins-x-0-5rem > * + * {
  margin-left: 0.5rem;
}

.child-margins-y-0-5rem > * + * {
  margin-top: 0.5rem;
}

.child-margins-x-1rem > * + * {
  margin-top: 1rem;
}

.child-margins-y-1rem > * + * {
  margin-top: 1rem;
}

.child-margins-y-1-25rem > * + * {
  margin-top: 1.25rem;
}

.child-margins-y-1-5rem > * + * {
  margin-top: 1.5rem;
}

.child-margins-y-2rem > * + * {
  margin-top: 2rem;
}

.child-margins-x-4 > * + * {
  margin-left: 4px;
}
.child-margins-x-8 > * + * {
  margin-left: 8px;
}
.child-margins-x-12 > * + * {
  margin-left: 12px;
}
.child-margins-x-16 > * + * {
  margin-left: 16px;
}
.child-margins-x-20 > * + * {
  margin-left: 20px;
}
.child-margins-x-24 > * + * {
  margin-left: 24px;
}
.child-margins-x-32 > * + * {
  margin-left: 32px;
}
.child-margins-x-48 > * + * {
  margin-left: 48px;
}
.child-margins-x-64 > * + * {
  margin-left: 64px;
}
.child-margins-x-128 > * + * {
  margin-left: 128px;
}
.child-margins-y-1rem > * + * {
  margin-top: 1rem;
}
.child-margins-y-2rem > * + * {
  margin-top: 2rem;
}
.child-margins-y-2 > * + * {
  margin-top: 2px;
}
.child-margins-y-4 > * + * {
  margin-top: 4px;
}
.child-margins-y-8 > * + * {
  margin-top: 8px;
}
.child-margins-y-12 > * + * {
  margin-top: 12px;
}
.child-margins-y-16 > * + * {
  margin-top: 16px;
}
.child-margins-y-20 > * + * {
  margin-top: 20px;
}
.child-margins-y-24 > * + * {
  margin-top: 24px;
}
.child-margins-y-32 > * + * {
  margin-top: 32px;
}
.child-margins-y-48 > * + * {
  margin-top: 48px;
}
.child-margins-y-64 > * + * {
  margin-top: 64px;
}
.child-margins-x-128 > * + * {
  margin-top: 128px;
}
.child-margins-x-hairline > * + * {
  margin-left: 1px;
}
.child-margins-y-hairline > * + * {
  margin-top: 1px;
}

.padding-0-5rem {
  padding: 0.5rem;
}
.padding-0-75rem {
  padding: 0.75rem;
}
.padding-1rem {
  padding: 1rem;
}

.padding-hairline {
  padding: 1px;
}

.padding-1rem {
  padding: 1rem;
}

.padding-2rem {
  padding: 2rem;
}

.padding-3rem {
  padding: 3rem;
}

.padding-4rem {
  padding: 4rem;
}

.padding-5rem {
  padding: 5rem;
}

.padding-6rem {
  padding: 6rem;
}

.padding-4 {
  padding: 4px;
}
.padding-8 {
  padding: 8px;
}
.padding-12 {
  padding: 12px;
}
.padding-16 {
  padding: 16px;
}
.padding-20 {
  padding: 20px;
}
.padding-24 {
  padding: 24px;
}
.padding-32 {
  padding: 32px;
}
.padding-48 {
  padding: 48px;
}
.padding-64 {
  padding: 64px;
}
.padding-128 {
  padding: 128px;
}
.padding-top {
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.padding-right {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.padding-bottom {
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
}
.padding-left {
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
}
.padding-x {
  padding-top: 0;
  padding-bottom: 0;
}
.padding-y {
  padding-left: 0;
  padding-right: 0;
}
.padding-top-0 {
  padding-top: 0;
}
.padding-right-0 {
  padding-right: 0;
}
.padding-bottom-0 {
  padding-bottom: 0;
}
.padding-left-0 {
  padding-left: 0;
}
.padding-0 {
  padding: 0;
}

.margin-auto {
  margin: auto;
}
.margin-auto-x {
  margin-left: auto;
  margin-right: auto;
}
.margin-auto-y {
  margin-top: auto;
  margin-bottom: auto;
}
.margin-0-5rem {
  margin: 0.5rem;
}
.margin-1rem {
  margin: 1rem;
}
.margin-1-25rem {
  margin: 1.25em;
}
.margin-1-5rem {
  margin: 1.5rem;
}
.margin-2rem {
  margin: 2rem;
}

.margin-3rem {
  margin: 3rem;
}

.margin-4rem {
  margin: 4rem;
}

.margin-5rem {
  margin: 5rem;
}

.margin-hairline {
  margin: 1px;
}
.margin-2 {
  margin: 2px;
}
.margin-4 {
  margin: 4px;
}
.margin-6 {
  margin: 6px;
}
.margin-8 {
  margin: 8px;
}
.margin-12 {
  margin: 12px;
}
.margin-16 {
  margin: 16px;
}
.margin-20 {
  margin: 20px;
}
.margin-24 {
  margin: 24px;
}
.margin-28 {
  margin: 28px;
}
.margin-32 {
  margin: 32px;
}
.margin-48 {
  margin: 48px;
}
.margin-64 {
  margin: 64px;
}
.margin-128 {
  margin: 128px;
}
.negative-margin-2 {
  margin: -2px;
}
.negative-margin-4 {
  margin: -4px;
}
.negative-margin-8 {
  margin: -8px;
}
.negative-margin-12 {
  margin: -12px;
}
.negative-margin-16 {
  margin: -16px;
}
.negative-margin-20 {
  margin: -20px;
}
.negative-margin-24 {
  margin: -24px;
}
.negative-margin-32 {
  margin: -32px;
}
.negative-margin-48 {
  margin: -48px;
}
.negative-margin-64 {
  margin: -64px;
}
.negative-margin-128 {
  margin: -128px;
}
.margin-top {
  margin-right: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.margin-right {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
}
.margin-bottom {
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
}
.margin-left {
  margin-top: 0;
  margin-right: 0;
  margin-bottom: 0;
}
.margin-x {
  margin-top: 0;
  margin-bottom: 0;
}
.margin-y {
  margin-left: 0;
  margin-right: 0;
}
.margin-top-0 {
  margin-top: 0;
}
.margin-right-0 {
  margin-right: 0;
}
.margin-bottom-0 {
  margin-bottom: 0;
}
.margin-left-0 {
  margin-left: 0;
}
.margin-0 {
  margin: 0;
}
